<template>
  <div class="body">
    <div class="content">
      <div class="title">
        <span>小程序信息</span>
      </div>
      <div style="margin: 20px;"></div>
      <div class="list">
        <el-form label-width="100px" size="mini">
          <el-form-item label="AppID">
            <el-input v-model="wxXcx.appid" size="large"></el-input>
          </el-form-item>
          <el-form-item label="AppSecret">
            <el-input v-model="wxXcx.appSecret" size="large"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
 <el-row :gutter="20">
      <el-col :offset="3">
        <el-button type="primary" @click="submit">保存</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import http from '@/utils/cloud'
import global from '@/config/global'

export default {
  data () {
    return {
      wxXcx: {
        appid: '',
        appSecret: '',
        wxPay: {
          appid: '',
          mchId: '',
          v3Key: '',
          appCertContent: '',
          appPrivateKeyContent: ''
        },
        alipay: {
          appId: '',
          mchId: '',
          publicKey: '',
          privateKey: ''
        }
      }
    }
  },
  mounted () {
    // 是否是demo演示环境
    if (global.demo) {
      this.$message({
        message: '当前是演示环境，此设置只可参看，无法修改~',
        type: 'warning',
        center: true
      })
      return
    }
    this.getSystem()
  },
  methods: {
    getSystem () {
      http
        .POST('/jw-admin', {
          module: 'settings',
          operation: 'getSystem'
        })
        .then(res => {
          if (res) {
            if (res.channel.wxXcx) {
              this.wxXcx = res.channel.wxXcx
            }
          }
        })
    },
    submit () {
      // 是否是demo演示环境
      if (global.demo) {
        this.$message({
          message: '当前是演示环境，此设置只可参看，无法修改~',
          type: 'warning',
          center: true
        })
        return
      }
      http
        .POST('/jw-admin', {
          module: 'settings',
          operation: 'updateSystem',
          channel: {
            wxXcx: this.wxXcx
          }
        })
        .then(res => {
          this.$message({
            message: '设置成功',
            type: 'success'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.body {
  width: 100%;
  padding: 15px;
  .list {
    width: 500px;
  }
  .content {
    height: 100%;
    background: #fff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    margin-bottom: 20px;

    .title {
      font-size: 18px;
      font-weight: bold;
      padding-left: 12px;
      border-left: 6px solid #6775e1;
    }
  }
}
</style>
